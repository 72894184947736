#scrollBtn {
    width: 7vh;
    height: 7vh; 
    position: absolute;
    bottom: 0;
    right: 5%;  
    background-color: #e6dfdfe1;
    box-shadow: 1px 4px 6px #00000080;
}

#scrollBtn:hover {
    background-color: #2849a58e;
}

#scrollBtn:hover #scrollBtnStroke {
    stroke: #e6dfdfe1;
}

@media(orientation: landscape) {
    #scrollBtn {
        width: 7vw;
        height: 7vw;
    }
}
