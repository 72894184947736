section.gallerysRoute {
    padding-bottom: 50px;
    position: relative;
    top: 0;
    left: 0;
}

#gardenSection #fruitsSection #flowersSection, h1 {
    margin-bottom: 1rem;
    padding: 2vh;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

#gardenSection #fruitsSection #flowersSection, ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    row-gap: 1rem;
    column-gap: 1rem;
    list-style: none;
}

#gardenSection #fruitsSection #flowersSection, img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    border: 1px solid #000;
    box-shadow: 1px 4px 6px #00000080
}

#gardenSection #fruitsSection #flowersSection, #slideWindow, .exit {
    display: none;
}

@media(orientation: portrait) and (min-width: 360px) {
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 2.8rem;
    }
}

@media(orientation: portrait) and (min-width: 410px) {
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 3.2rem;
    }
}

@media(orientation: portrait) and (min-width: 600px) {    
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 4.6rem;
    }

    #gardenSection #fruitsSection #flowersSection, ul {
        padding: 1rem 0 10rem;
    }
}

@media(orientation: portrait) and (min-width: 1024px) {   
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 8rem;
    }
}

@media(orientation: landscape) {  
    #gardenSection #fruitsSection #flowersSection, h1 {        
        font-size: 2.4rem;        
    }

    #gardenSection #fruitsSection #flowersSection, ul {       
        grid-template-columns: repeat(auto-fit, minmax(250px,1fr));      
    }
}

@media(orientation: landscape) and (min-width: 640px) {  
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 3rem;
    }
}

@media(orientation: landscape) and (min-width: 1024px) { 
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 6rem;
    }
}

@media(orientation: landscape) and (min-width: 1280px) {    
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 7rem;
    }

    #gardenSection #fruitsSection #flowersSection, ul {        
        padding: 1rem 0 30rem;            
    }
}

@media(min-width: 1280px) {
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 7rem;
    }

    #gardenSection #fruitsSection #flowersSection, ul {        
        padding: 1rem 0 30rem;            
    }

    #gardenSection #fruitsSection #flowersSection, img {
       cursor: pointer;
    }

    #slideWindow.active {
        display: block;        
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 0 1vh #ccc;
    }

    .exit {
        display: block;
        width: 5vh;
        height: 5vh;    
        position: absolute;
        top: 5%;
        right: 5%;
        border: none;
        background-image: url('../images/exitDark.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #fff;
        outline: none;
        cursor: pointer;
    }
}

@media(min-width: 1600px) {    
    #gardenSection #fruitsSection #flowersSection, h1 {
        font-size: 8rem;
    }
}