nav#topmenu {
    grid-column: 1;
    grid-row: 2;    
}

nav#topmenu ul {        
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 0;
    place-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    list-style-type: none;
    transform: scale(0);
    transition: .5s ease;   
}

nav#topmenu ul li:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
}

nav#topmenu ul li:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
}

nav#topmenu ul li:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
}

nav#topmenu ul li span {
    display: flex;
    width: 20vw;
    height: 20vw;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #6d8393;
    font-size: 4rem;
}

nav#topmenu ul li span:hover {
    background-color: #2423238e;
}

nav#topmenu ul li span.home {
    color: #491515cc;
}

nav#topmenu ul li span.seedling {
    color: #c2126ad2;
}

nav#topmenu ul li span.apple-alt {
    color: #da3a13d7;
}

@media(orientation: portrait) and (min-width: 375px) {
    nav#topmenu ul li span {
        font-size: calc(4rem + 1vh);
    }
}

@media(orientation: portrait) and (min-width: 600px) {    
    nav#topmenu ul li span {
        font-size: calc(5rem + 1vh);
    }
}

@media(orientation: portrait) and (min-width: 768px) {    
    nav#topmenu ul li span {
        font-size: calc(7rem + 1vh);
    }
}

@media(orientation: portrait) and (min-width: 1024px) {    
    nav#topmenu ul li span {
        font-size: calc(9rem + 1vh);
    }
}

@media(orientation: landscape) {
    nav#topmenu ul {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    nav#topmenu ul li:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
    }
    
    nav#topmenu ul li:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
    }
    
    nav#topmenu ul li:nth-child(3) {
        grid-column: 3;
        grid-row: 1;
    }

    nav#topmenu ul li span {
        width: 20vh;
        height: 20vh;
    }
}

@media(orientation: landscape) and (min-width: 700px) {
    nav#topmenu ul li span {
        font-size: 5rem;
    }
}

@media(orientation: landscape) and (min-width: 1024px) {  
    nav#topmenu ul li span {
        font-size: 8rem;
    }
}

@media(orientation: landscape) and (min-width: 1280px) {    
    nav#topmenu ul li span {
        font-size: 9rem;
    }
}

@media(min-width: 1280px) {
    nav#topmenu ul li span {
        font-size: 9rem;
    }
}