button {
    border: none;
    outline: none;
}

button::-moz-focus-inner {
    border: none;
}

#burgerBtn {
    grid-column: 2;
    grid-row: 1;
    width: 17vw;
    height: 17vw;
    justify-self: center;
    align-self: center;
    border-radius: 50%;
    box-shadow: 0 0 0 3vw #022005c2;
    background: #5f9ea0c2;
    transition: .5s ease;
}

#burgerBtn span.frog {    
    align-self: center;
    justify-self: center;
    font-size: calc(1.8rem + 3vh);
    color: #022005c2;    
}

#burgerBtn.active {
    box-shadow: 0 0 0 3vw #026873;   
    background: #d96f48cc;
}

#burgerBtn.active~nav#topmenu>ul {
    transform: scale(1);
}

@media(orientation: portrait) and (min-width: 600px) {
    #burgerBtn span.frog {
        font-size: calc(3.5rem + 3vh);
    }
}

@media(orientation: portrait) and (min-width: 768px) {
    #burgerBtn span.frog {
        font-size: calc(5rem + 3vh);
    }
}

@media(orientation: portrait) and (min-width: 1024px) {
    #burgerBtn span.frog {
        font-size: calc(6rem + 3vh);
    }
}

@media(orientation: landscape) {
    #burgerBtn {
        grid-row: 1/3;   
        align-self: start;    
        width: 17vh;
        height: 17vh;
        margin-top: 4vw;
        box-shadow: 0 0 0 3vh #022005c2;           
    }
    
    #burgerBtn.active {
        box-shadow: 0 0 0 3vh #026873;
    }

    #burgerBtn span.frog {    
        font-size: calc(1.8rem + 3vw);        
    }  
    
}

@media(orientation: landscape) and (min-width: 960px) {
    #burgerBtn span.frog {
        font-size: calc(3rem + 3vw);
    }
}

@media(min-width: 1280px) {
    #burgerBtn {
        cursor: pointer;
    }
}
