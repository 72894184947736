header {
    max-width: 1920px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;   
}

main {    
    display: none;   
    max-width: 1920px;
    min-height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    padding: 2rem 1rem;
    background-color: #cccccc4b;
    overflow: hidden;
}

main.active {
    display: block;
}

footer{
    max-width: 1920px;
    height: 20vh;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    height: 15vh;
    overflow: hidden;
    padding: 4vh 0;
    background-color: #000000e8;    
}
