footer p {
    width: 90%;
    margin: auto;
    text-align: center;
    font-size: calc(0.8rem + 1vh);    
    letter-spacing: 0.2rem;
    color: #fff;
    line-height: 1.5;
}

@media(orientation: portrait) and (min-width: 1024px) {
    footer p {
        font-size: calc(1rem + 1vh);
    }
}

@media(orientation: landscape) {  
    footer p {       
        line-height: 1.2; 
    }
}

@media(orientation: landscape) and (min-width: 1280px) {    
    footer p {
        font-size: calc(1rem + 1vw);
    }
}