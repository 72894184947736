div.wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 73% 27%;
    grid-template-rows: 20% 60% 20%;
    background-image: url('../images/640x480.webp');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
    overflow: hidden;
}
header h1 {
    grid-column: 1;
    grid-row: 3;
    display: flex;
    align-items: center;
    height: 50%;
    padding-left: 1vh;
    font-size: calc(1.5rem + 1vh);
    text-align: left;
    text-transform: uppercase;    
    color: #0c0c0cf3;
    background-color: #5f9ea0c2; 
}

@media(orientation: portrait) and (min-width: 360px) {
    header h1 {
        font-size: calc(1.7rem + 1vh);
    }
}

@media(orientation: portrait) and (min-width: 600px) {    
    header h1 {
        font-size: calc(2.4rem + 1vh);
        letter-spacing: 0.2rem;
    }
}

@media(orientation: portrait) and (min-width: 760px) {    
    header h1 {
        font-size: calc(3rem + 1vh);
    }
}

@media(orientation: portrait) and (min-width: 1024px) {    
    header h1 {
        font-size: calc(4rem + 1vh);
    }
}

@media(orientation: landscape) {  
    div.wrapper {
        grid-template-rows: 40% 40% 20%;
    }

    header h1 {
        width: 70%;
        height: 70%;
        padding-left: 2vw;
        font-size: calc(2rem + 1vw);
    }
}

@media(orientation: landscape) and (min-width: 900px) {  
    header h1 {
        font-size: calc(3rem + 1vw);        
    }
}

@media(orientation: landscape) and (min-width: 1280px) {    
    header h1 {
        font-size: calc(4rem + 1vw);
    }
}

@media(min-width: 1600px) {    
    header h1 {
        font-size: calc(6rem + 1vw);
    }
}