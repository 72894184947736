@media(min-width: 1280px) {
    #exit {
        width: 5vh;
        height: 5vh;    
        position: absolute;
        top: 5%;
        right: 5%;
        background-color: #e6dfdfe1;       
    }

    #exit:hover {
        background-color: #2849a58e; 
    }

    #exit:hover .crossExit {
        stroke: #e6dfdfe1;
    }
}